<template>
  <div class="menubar" :class="{ dark: dark, light: !dark, active: menuout }">
    <div class="container is-max-desktop is-hidden-mobile">
      <div class="columns is-vcentered is-centered">
        <div class="column is-narrow" v-for="item in menuitems" :key="item.link">
          <router-link :to="item.link" class="menuitem"
            :class="{ dark: dark, light: !dark, selected: $route.path==item.link }">
            <div class="itembody" :class="{ dark: dark, light: !dark }">
              <div class=" columns is-vcentered is-gapless">
                <div class="column"><img class="cicon" :src="getIcon(item.icon)" /></div>
                <div class="column is-narrow">{{item.name[lang_number]}}</div>
              </div>
            </div>
          </router-link>

        </div>
        <div class="column is-narrow">
          <a class="menuitem" @click="isLanguageModalActive = !isLanguageModalActive"
            :class="{ dark: dark, light: !dark }">
            <div class="itembody" :class="{ dark: dark, light: !dark }">
              <div class=" columns is-vcentered is-gapless">
                <div class="column"><img class="cicon" :src="getIcon('lang')" /></div>
                <div class="column is-narrow">LANGUAGE</div>
              </div>
            </div>
          </a>

        </div>
      </div>
    </div>
    <div class="menumobile is-hidden-tablet has-text-centered" @click="menuout = false" :class="{ hidden: !menuout }">
      <img class="clogo" src="../assets/logo.svg" />
      <ul>
        <li v-for="item in menuitems" :key="item.link">
          <router-link :to="item.link" class="menuitem"
            :class="{ dark: dark, light: !dark, selected: $route.path==item.link }">
            <div class="itembody" :class="{ dark: dark, light: !dark }">
              <div class=" columns is-mobile is-vcentered is-gapless">
                <div class="column is-narrow"><img class="cicon" :src="getIcon(item.icon)" /></div>
                <div class="column is-narrow">{{item.name[lang_number]}}</div>
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <a class="menuitem" @click="isLanguageModalActive = !isLanguageModalActive"
            :class="{ dark: dark, light: !dark }">
            <div class="itembody" :class="{ dark: dark, light: !dark }">
              <div class=" columns is-mobile is-vcentered is-gapless">
                <div class="column is-narrow"><img class="cicon" :src="getIcon('lang')" /></div>
                <div class="column is-narrow">LANGUAGE</div>
              </div>
            </div>
          </a>

        </li>
      </ul>
    </div>
    <div class="menubutton is-hidden-tablet">
      <div class="itembody2" @click="menuout = !menuout" :class="{ dark: dark, light: !dark }">
        <div class=" columns is-mobile is-vcentered is-centered is-gapless">
          <div class="column is-narrow" v-if="!menuout"><img class="cicon" src="../assets/menubutton_b.svg" /></div>
          <div class="column is-narrow" v-if="menuout"><img class="cicon" src="../assets/menuclose.svg" /></div>
          <div class="column is-narrow" v-if="!menuout">{{menuname[lang_number]}}</div>
          <div class="column is-narrow" v-if="menuout">{{menuclose[lang_number]}}</div>
        </div>
      </div>
    </div>
    <b-modal v-model="isLanguageModalActive" :width="640" scroll="keep">
      <div class="card" @mouseover.ctrl="showEasterEgg">
        <div class="card-content">
          <p class="has-text-centered has-text-weight-bold">Select your language</p>
          <br />
          <div class="columns is-multiline">
            <div class="column has-text-centered">
              <a @click="changeLanguage('cs')">
                <gb-flag code="cz" size="medium" />
                <p class="has-text-weight-bold">čeština</p>
                <p>Czech</p>
              </a>
            </div>
            <div class="column has-text-centered">
              <a @click="changeLanguage('de')">
                <gb-flag code="de" size="medium" />
                <p class="has-text-weight-bold">Deutsch</p>
                <p>German</p>
              </a>
            </div>
            <div class="column has-text-centered">
              <a @click="changeLanguage('pl')">
                <gb-flag code="pl" size="medium" />
                <p class="has-text-weight-bold">polski</p>
                <p>Polish</p>
              </a>
            </div>
            <div class="column has-text-centered">
              <a @click="changeLanguage('en')">
                <gb-flag code="gb" size="medium" />
                <p class="has-text-weight-bold">English</p>
              </a>
            </div>
            <div class="column has-text-centered" v-if="!hiddenEG">
              <a @click="changeLanguage('ko')">
                <gb-flag code="kr" size="medium" />
                <p class="has-text-weight-bold">한국어</p>
                <p>Korean</p>
              </a>
            </div>
            <div class="column has-text-centered" v-if="!hiddenEG">
              <a @click="changeLanguage('ns')">
                <gb-flag code="fe" size="medium" />
                <p class="has-text-weight-bold">Niyīmal</p>
                <p>Nissiian</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'MenuBar',
    props: {
      lang_number: Number,
      lang_code: String,
      dark: Boolean
    },
    data() {
      return {
        isLanguageModalActive: false,
        hiddenEG: true,
        menuname: [
          "MENU",
          "MENU",
          "메뉴",
          "MENU",
          "MAENU",
          "MENU"
        ], 
        menuclose: [
          "ZAVŘÍT",
          "CLOSE",
          "닫다",
          "ZUMACHEN",
          "KLAEROOAETT",
          "ZAMKNĄĆ"
        ],
        menuout: false,
        menuitems: [{
            name: [
              "UBYTOVÁNÍ",
              "ACCOMODATION",
              "숙박 시설",
              "EINKEHR",
              "SETIDOLL",
              "NOCLEG"
            ],
            icon: "ubyt",
            link: "/ubytovani"
          },
          {
            name: [
              "KONTAKT",
              "CONTACT",
              "연락",
              "KONTAKT",
              "KXONTAKT",
              "KONTAKT"
            ],
            icon: "kontakt",
            link: "/kontakt"
          },
          {
            name: [
              "CENÍK",
              "PRICING",
              "가격표",
              "PREISLISTE",
              "RHERĒIMIN",
              "CENNIK"
            ],
            icon: "cen",
            link: "/cenik"
          },
          {
            name: [
              "LOKALITA",
              "LOCALITY",
              "위치",
              "LOKALITÄT",
              "ĀGRONSEOT",
              "POŁOŻENIE"
            ],
            icon: "lokac",
            link: "/lokalita"
          }
        ]
      }
    },
    methods: {
      changeLanguage: function(code) {
        this.isLanguageModalActive = false;
        this.$cookie.set("language", code); 
        this.$parent.lang = code;

      },
      showEasterEgg: function() {
        this.hiddenEG = false;
        console.log(this.$route);

      }
    },
    computed: {
      getIcon() {
        if (this.dark) {
          return filename => require("../assets/" + filename + "_b.svg");
        } else {
          return filename => require("../assets/" + filename + "_w.svg");
        }
      }
    }
  }
</script>

<style scoped>
  .cicon {
    width: 28px;
    margin-right: 5px;
    margin-bottom: -5px;
  }

  .menubar {
    height: 60px;
    position: fixed;
    z-index: 30;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 5px;
    transition: all 0.5s ease;
  }

  .menubar.active {
    height: 100%;
    transition: all 0.5s ease;
    background: #181b03;
    background: linear-gradient(180deg, #181b03 87%, rgba(129, 0, 18, 1) 100%);
  }

  .menubar.dark {
    background-color: #181b03;
  }

  .menubar.light {
    background-color: #e3e4dc;
  }
.clogo {
  width: 70vw;

  margin-top: 20px;
  margin-bottom: 50px;
}
  .menuitem, .menubutton {
    color: white;
    font-weight: bold;
    width: auto;
  }
.menumobile.hidden {
display:none;
}
.menubutton{
  position:fixed;
  bottom: 5px;
  width:100%;
  left:0px;
}
  .menuitem.light {
    color: black;
  }

  .itembody,.itembody2 {

    border-radius: 5px;
    padding: 10px;
  }

  .itembody:hover {
    background-color: #505243;
  }

  .itembody.light:hover {
    background-color: rgb(179, 180, 170)
  }
    .selected .itembody {
      background-color: #505243!important
    }.selected .itembody.light {
      background-color: rgb(179, 180, 170)!important
    }
</style>