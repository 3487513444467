<template>
  <div class="topbar">
    <div class=" container  is-max-desktop">
      <div class="columns is-vcentered d is-centered">
        <div class="column is-narrow  is-hidden-mobile">
          <div class="columns is-gapless">
            <div class="column"><img class="cicon ph" src="../assets/phone.svg" /></div>
            <div class="column is-narrow"><a :href="phone_num_link" class="has-text-white">{{ phone_num }}</a></div>
          </div>
        </div>
        <div class="column has-text-centered">
          <router-link to="/"><img class="clogo" src="../assets/logo.svg" /></router-link>
        </div>
        <div class="column is-narrow is-hidden-mobile">
          <div class="columns is-gapless">
            <div class="column"><img class="cicon ma" src="../assets/mail.svg" /></div>
            <div class="column is-narrow"><a :href="mail_addr_link" class="has-text-white">{{ mail_addr }}</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      phone_num: "+420 724 065 993",
      mail_addr: "rezervace@domanapeci.cz",
      phone_num_link: "tel:+420724065993",
      mail_addr_link: "mailto:rezervace@domanapeci.cz"
    }
  } 
}
</script>

<style scoped>

.topbar {
  color: white;
  font-size: 16px;
  padding-top: 16px;
  position: fixed;
  z-index: 6000;
  pointer-events: none;
  width: 100vw; 
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.396796218487395) 0%, rgba(129, 0, 18, 0)100%);
}
.cicon {
  width: 28px;
  pointer-events: auto;
}
.cicon.ph {
  margin-right: 9px;
}
.cicon.ma {
  margin-right: 9px;
  margin-top: -2px;
}
.clogo {
  pointer-events: auto;
    width: 200px;
}
.d
{
  pointer-events: auto;
}
@media screen and (max-width: 992px)
{
  .clogo {
      width: 150px;
    }
}

</style>
