import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: "cs"
  },
  mutations: {
    UPDATE_LANGUAGE(state, payload) {
      state.language = payload
    }
  },
  actions: {
    setLanguage(context, state) {
      state.language = context;
    }
  },
  modules: {
  },
  getters: {
    languageCode: function (state) {
      return state.language;
    }
 }
})
