<template>
  <div class="columns is-gapless is-multiline is-mobile is-marginless is-vertical is-centered">

    <div class="column is-narrow has-text-centered">
      <img :src="leader" class="leader is-hidden-mobile" />
      <img :src="leader_mobile" class="leader_m is-hidden-tablet" />

      <div class="container is-max-desktop cntr">
        <div class="columns is-vcentered">
          <div class="column mpd is-narrow is-hidden-mobile has-text-right has-text-weight-bold has-text-white"
            v-html="availability[lang_number]"></div>
          <div
            class="column mpd is-full is-hidden-tablet is-size-3 has-text-centered has-text-weight-bold has-text-white"
            v-html="availability_m[lang_number]"></div>
          <div class="column mpd ">
            <b-datepicker v-model="availability_date.start" :show-week-number="true" :locale="locale[lang_number]"
              :placeholder="placeholder.arrival[lang_number]" icon="calendar-today" position="is-top-left" trap-focus
              @input="arrival_change" :selectable-dates="selectableDatesAr">
            </b-datepicker>
          </div>
          <div class="column mpd">
            <b-datepicker v-model="availability_date.end" :show-week-number="true" :locale="locale[lang_number]"
              :placeholder="placeholder.depart[lang_number]" :selectable-dates="selectableDatesDe" icon="calendar-today"
              position="is-top-left" trap-focus>
            </b-datepicker>
          </div>
          <div class="column mpd is-narrow">
            <b-button @click="searchh" type="is-primary is-radiusless has-text-weight-bold">
              {{search[lang_number]}}</b-button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var date = new Date();
date.setDate(date.getDate() + 1);
  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        availability: [
          "Zjistěte<br/>dostupnost",
          "Check for <br/> availability",
          "가용성에 <br/>대해 확인",
          "Überprüfen Sie <br/> die Verfügbarkeit",
          "Koōkotti<br/>sonken",
          "Sprawdź <br/> dostępność"
        ],
        availability_m: [
          "Zjistěte dostupnost",
          "Check for availability",
          "가용성에 대해 확인",
          "Überprüfen Sie die Verfügbarkeit",
          "Koōkotti sonken",
          "Sprawdź dostępność"
        ],
        search: [
          "HLEDAT",
          "SEARCH",
          "검색",
          "SUCHE",
          "NARAETTI",
          "SZUKAĆ"
        ],
        locale: [
          "cs-CZ",
          "en-US",
          "ko-KR",
          "de-DE",
          "cs-CZ",
          "pl-PL"
        ],
        placeholder: {
          arrival: [
            "PŘÍJEZD",
            "ARRIVAL",
            "도착",
            "ANKUNFT",
            "KXĒTINNAE",
            "PRZYJAZD"
          ],
          depart: [
            "ODJEZD",
            "DEPARTURE",
            "출발",
            "ABFAHRT",
            "RHIITINNAE",
            "WYJAZD"
          ]
        },
        availability_date: {
          start: null,
          end: null
        },
        selected: {
          people: 0,
          arrival: new Date(),
          depart: date,
        },

      }
    },
    methods: {
      addDays: function (date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      },
      arrival_change: function () {
        this.availability_date.end = this.addDays(this.availability_date.start, 7);
      },
      selectableDatesAr: function (date) {
        if (date >= new Date()) {
          return true
        }
        else {
          return false;
        }
      }, selectableDatesDe: function (date) {
        if (date >= new Date()) {
          if (date >= this.availability_date.start) { return true; }
          else { return false; }
        }
        else {
          return false;
        }
      },
      searchh: function() {
        this.$router.push({
          name: 'Cenik',
          params: { dates: this.availability_date }
        })
      }
    },
    computed: {
      leader() {
        return require("../assets/leader_" + this.lang + ".svg");
      },
      leader_mobile() {
        return require("../assets/leader_" + this.lang + "_m.svg");
      },
      lang_number() {
        return this.$parent.lang_number;
      },
      lang() {
        return this.$parent.lang;
      }
  }, created() {
    this.$parent.dark = true;
  }
  }
</script>

<style scoped>
  .leader {
    width: 600px;
    margin-bottom: 1rem;
  }
        .leader_m {
          width: 300px
        }
.availselect .input {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0px;
  border: 3px white solid;
}
  .is-vertical {
    width: 100%;
    height: calc(100%);
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(0, 0, 0, 0.396796218487395) 0%, rgba(129, 0, 18, 0) 45%);
  }
  @media screen and (max-width: 992px)
{
    .is-vertical {
    height: calc(100% - 60px - 97px);
    }
    .cntr
    {
      margin-left: 35px;margin-right: 35px;
    }
    .mpd {
      padding-bottom: 0px;
    }
  
}
</style>