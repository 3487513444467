<template>
  <div id="app">
    <TopBar></TopBar>
    <transition name="slide-fade">
      <router-view />
    </transition>
    <MenuBar :lang_number="lang_number" :lang_code="lang" :dark="dark"></MenuBar>
  </div>
</template>
<script>
import TopBar from '@/components/TopBar.vue';
import MenuBar from '@/components/MenuBar.vue';
export default {
  components: {
    TopBar,MenuBar
  },
  data() {
    return {
      lang: "cs",
      dark: true
    }
  },
  computed: {
    lang_number() {
      switch(this.lang) {
        case "cs": return 0;
        case "en": return 1;
        case "ko": return 2;
        case "de": return 3;
        case "ns": return 4;
        case "pl": return 5;
      }
      return 0;
    }
  },
  created() {
    if (!this.$cookie.get("language"))
    {
      this.$cookie.set("language", "cs");
    }
    this.lang = this.$cookie.get("language");
    
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,700;1,400&display=swap');
* {

  font-family: 'Open Sans', sans-serif!important;
}
#app {
  background-image: url('./assets/background.png');
  height:100vh;
  width: 100vw;
  background-size: cover;overflow: hidden;
  background-position: center top;
  
}
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
