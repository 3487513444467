import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
     meta: { title: 'Domov' }
  },
  {
    path: '/lokalita',
    name: 'Lokalita',
    component: () => import('../views/Locality.vue'),
    meta: { title: 'Lokalita' }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Contact.vue'),
     meta: { title: 'Kontakt' }
  },
  {
    path: '/cenik',
    name: 'Cenik',
    component: () => import('../views/Pricing.vue'),
     meta: { title: 'Ceník' }
  },
  {
    path: '/ubytovani',
    name: 'Ubytovani',
    component: () => import('../views/Accomodation.vue'),
     meta: { title: 'Ubytování' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'Doma na peci';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
    });
});
export default router
